<template>
  <div>
    <q-card v-if="profile">
      <q-table
        class="sticky-action full-width"
        title="Educations"
        :rows="rows"
        :columns="columns"
        :filter="filter"
        row-key="id"
      >
        <template #top-left>
          <span class="text-h6 q-ml-sm">Educations</span>
        </template>
        <template #top-right>
          <q-input dense debounce="300" color="primary" v-model="filter">
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
        </template>
      </q-table>
    </q-card>
  </div>
</template>

<script>
import { computed, ref } from '@vue/runtime-core'
import { formatDate, capitalize } from '../utils/functions'
import { useStore } from 'vuex'

export default {
  setup () {
    const store = useStore()
    const profile = computed(() => store.state.profile)

    const filter = ref('')

    const columns = ref([
      { name: 'name', label: 'Name', align: 'left', field: 'name', sortable: true },
      { name: 'majors', align: 'left', label: 'Majors', field: 'majors', sortable: true },
      { name: 'degree', align: 'left', label: 'Degree', field: 'degree', sortable: true, format: val => capitalize(val) },
      { name: 'isCurrent', label: 'Current', field: 'isCurrent', sortable: false, format: val => val ? 'Yes' : 'No' },
      { name: 'location', label: 'Location', field: 'location', sortable: true },
      { name: 'start', label: 'Start', field: 'start', format: val => formatDate(val, 'YYYY, MMM') },
      { name: 'end', label: 'End', field: 'end', format: val => formatDate(val, 'YYYY, MMM') }
    ])

    const rows = computed(() => store.state.educations)

    return {
      profile,
      filter,
      columns,
      rows
    }
  }
}
</script>
